const region = 'eu-central-1';
const userPoolId = 'eu-central-1_ZWhd0QrJN';
const userPoolWebClientId = '2it9lpg5pva7r7g12qbl507118';
const identityPoolId = 'eu-central-1:943748da-2190-42c1-9a05-b43980cb193a';

export const environment = {
  production: false,
  apiUrl: 'https://api.audax.powerdata.de/il-backend',
  clientName: 'audax-prod',
  dashboardItemsCount: 2,
  sftpHost: 's-bb790cebdc1844029.server.transfer.eu-central-1.amazonaws.com',
  sftpPort: 22,
  AWS: {
    amplifyConfig : {
      Auth: {
        region,
        userPoolId,
        userPoolWebClientId,
        identityPoolId
      }
    }
  },
  translation: {
    default: 'en',
    languages: [
      { name: 'English', key: 'en' },
      { name: 'Deutsch', key: 'de' }
    ]
  }
};
